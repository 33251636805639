import { render, staticRenderFns } from "./cliengo.vue?vue&type=template&id=da65b360"
import script from "./cliengo.vue?vue&type=script&lang=js"
export * from "./cliengo.vue?vue&type=script&lang=js"
import style0 from "./cliengo.vue?vue&type=style&index=0&id=da65b360&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports