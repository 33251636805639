<template>
    <v-card class="ma-0" v-if="propData.lead!=undefined">
        <v-toolbar class="pl-3 pr-6" color="#ededed" elevation="0" style="border-bottom:solid #ccd2d4 2px;">
            <v-list-item-avatar size="30" color="#ccd2d4">
                <v-img v-if="lead.conversation!=undefined && lead.conversation.client_picture!=null" :src="lead.conversation.client_picture"></v-img>
                <span style="text-transform: uppercase; text-align: center; width: 30px;" class="white--text text-h6" v-else><strong>{{lead.name.slice(0,1)}}</strong></span>
            </v-list-item-avatar>
            <div v-if="lead.name!=undefined">
                <span v-if="this.lead.funnel_phase.funnel.name!='Clientes'">{{lead.name}} {{lead.last}}</span> 

                
            
                <v-btn text v-else :to="{ path: '/clients/client/'+ lead.id}" target="_blank">{{lead.name}}</v-btn> <span style="font-size:12px; font-weight:500;">{{propData.lead.conversation.channelId}}</span>
            </div>
            <v-spacer></v-spacer>
            <v-row class="my-0" style="transform:scale(0.8); margin-right: -8%;">
                <!--v-select style="width:80px;" class="mt-1" :items="funnels" rounded outlined dense v-model="newFunnel" small item-text="name" item-value="id"></v-select-->
                <v-select style="width:80px;" class="mt-1 mx-4" :items="phases" rounded outlined dense v-model="newPhase" small item-text="name" item-value="id"></v-select>
                <v-btn rounded color="primary" :disabled="buttonDisabled" @click="saveFunelAndFace()" class="elevation-0 mt-1">MOVER</v-btn>
            </v-row>
            <!--v-btn icon>
                <v-icon color="#919191">mdi-magnify</v-icon>
            </v-btn-->
        </v-toolbar>
        <v-row class="ma-0">
            <v-col :md="columnaSize" class="pa-0" style="border-right:solid #ccd2d4 2px;">
                <!--v-toolbar class="px-2" color="white" elevation="0">
                    // :input="changePhase()"<v-select outlined dense class="mr-2" :items="funnel_phases" v-model="lead.funnel_phase.id" item-text="name" item-value="id" style="width:25%;"></v-select>
                    <v-select outlined dense class="mr-2" v-if="lead.user!=null" v-model="lead.user.id" :items="users" item-text="name" item-value="id" style="width:25%;">
                        <template v-slot:selection="{ item }">
                            <v-avatar size="32px" class="mr-4">
                                <img :src="item.profile_photo_url">
                            </v-avatar>
                            <span>{{item.name}} {{item.last}}</span>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-avatar size="32px" class="mr-4">
                                <img :src="item.profile_photo_url">
                            </v-avatar>
                            <span>{{item.name}} {{item.last}}</span>
                        </template>
                    </v-select>
                    <v-spacer></v-spacer>
                </v-toolbar-->
                <div  style="border-top:solid #ccd2d4 1px; background-color: white; background-repeat: repeat; background-size: 412.5px 749.25px; background-image: linear-gradient(120deg,rgb(229 221 213 / 90%) 60%,rgb(229 221 213 / 90%) 60%),url(https://unocrm.mx/wp-content/uploads/2021/07/bg-chat-tile-light_04fcacde539c58cca6745483d4858c52.png)!important;">
                    <div style="height:72vh;" class="chat__body pl-7 pr-10 py-0" ref="container">
                        <infinite-loading direction="top" @infinite="getMessages"></infinite-loading>
                        <!-- Message -->
                        <div v-for="(message_item, index) in messages" :key="index">
                            <v-row class="ma-0 mb-2">
                                <v-spacer></v-spacer>
                                <v-chip small style="font-weight:500;" color="#f0f0f0" class="elevation-1" light v-if="(index>=1 && message_item.created_at.slice(0,10)!=messages[index-1].created_at.slice(0,10)) || index==0">
                                    {{dateFormat(message_item.created_at)}}
                                </v-chip>
                                <v-spacer></v-spacer>   
                            </v-row>

                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <p v-bind="attrs" v-on="on" :class="message_item.direction === 'OUT' ? 'chat__sender chat__message': 'chat__reciever chat__message'" :style="'padding-right:'+ checkPadding(message_item.direction, message_item.contents.type=='file')"> 
                                        
                                        

                                        <span v-if="message_item.contents.type=='text' || message_item.contents.type=='template'">{{ message_item.contents.text }} </span>
                                        
                                        <img v-else-if="message_item.contents.type=='file' && message_item.contents.fileMimeType=='image/png'" style="margin-right:-30px; margin-bottom:5px; width:60px; max-height:60px; object-fit:cover;" :src="message_item.contents.fileUrl"/>

                                        <v-dialog content-class="elevation-0" v-else-if="message_item.contents.type=='file' && (message_item.contents.fileMimeType=='image/jpeg' || message_item.contents.fileMimeType=='jpeg' || message_item.contents.fileMimeType=='jpg' || message_item.contents.fileMimeType=='png')" style="height:auto; max-width:60px!important; max-width:600px!improtant;">
                                            <template v-slot:activator="{ on, attrs }">
                                                <img v-bind="attrs" v-on="on" :style="imageMargin(message_item.direction) + 'margin-bottom:5px; width:400px; max-height:400px; object-fit:cover;'" :src="message_item.contents.fileUrl"/>
                                            </template>
                                            <template v-slot:default="dialog">
                                                <div style="position: absolute; right: 0; top: 15px; width: 200px;">
                                                    <v-btn icon class="mx-5" @click="downloadWithAxios(message_item.contents.fileUrl)">
                                                        <v-icon color="white">mdi-download</v-icon>
                                                    </v-btn>
                                                    <v-btn icon color="primary" @click="dialog.value = false">
                                                        <v-icon color="white">mdi-close-circle</v-icon>
                                                    </v-btn>
                                                </div>
                                                <v-row class="ma-0 elevation-0">
                                                    <v-spacer/>
                                                    <img style="max-height:600px; width:auto;" :src="message_item.contents.fileUrl"/>
                                                    <v-spacer/>
                                                </v-row>
                                            </template>
                                        </v-dialog>

                                        <video style="height: 60px; width: 300px!important; transform: scale(.8); margin: -16px -39px; filter: brightness(1.1);" v-else-if="message_item.contents.type=='file' && message_item.contents.fileMimeType!=undefined && message_item.contents.fileMimeType.includes('ogg')" controls="" :autoplay="false" name="media">
                                            <source :src="message_item.contents.fileUrl" type="audio/ogg">
                                        </video>

                                        <video style="margin-right:-30px; margin-bottom:5px; max-width:400px; max-height:400px; object-fit:cover;" controls v-else-if="message_item.contents.fileMimeType=='video/mp4'||message_item.contents.fileMimeType=='mp4'||message_item.contents.fileMimeType=='mov'">
                                            <source :src="message_item.contents.fileUrl" type="video/mp4"/>
                                        </video>

                                        <v-btn style="letter-spacing: 0px;" color="primary" text class="py-6" v-else-if="message_item.contents.fileMimeType!=undefined && message_item.contents.fileMimeType.includes('application')" v-bind:href="message_item.contents.fileUrl" target="_blank">
                                            <v-icon class="mr-2">mdi-file-document</v-icon><span>{{message_item.contents.fileName}}</span><v-icon class="ml-2">mdi-download-circle-outline</v-icon>
                                        </v-btn>

                                        <v-btn style="letter-spacing: 0px;" color="primary" text class="pt-6 pb-5 mr-8" v-else-if="message_item.contents.fileMimeType == 'pdf'||message_item.contents.fileMimeType == 'xlsx'||message_item.contents.fileMimeType == 'doc'||message_item.contents.fileMimeType == 'ppt'" v-bind:href="message_item.contents.fileUrl" target="_blank">
                                            <v-icon class="mr-2">mdi-file-document</v-icon><span>{{message_item.contents.fileName}}</span><v-icon class="ml-2">mdi-download-circle-outline</v-icon>
                                        </v-btn>

                                        <span v-else-if="message_item.contents.type=='location'">
                                            <iframe :src="'https://maps.google.com/maps?q=' + message_item.contents.latitude + ',' + message_item.contents.longitude + '&hl=es;z=14&amp;output=embed'" 
                                                width="535" 
                                                height="300" 
                                                style="border:0;" 
                                                allowfullscreen="" 
                                                loading="lazy" 
                                                referrerpolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        </span>

                                        <span v-else-if="message_item.contents.type=='card'">
                                            <span>{{ message_item.contents.text }} </span>
                                            <v-row class="ma-0 mt-1">
                                                <v-btn disabled v-for="(button, index) in message_item.contents.buttons" v-bind:key="index" class="elevation-0 mt-1" style="min-width: 100%;" small>{{ button.text }}</v-btn>
                                            </v-row>
                                        </span>

                                        <span v-else-if="message_item.contents.type=='button'">
                                            <span>{{ message_item.contents.body }} </span>
                                            <v-row class="ma-0 mt-1">
                                                <v-btn disabled v-for="(button, index) in message_item.contents.buttons" v-bind:key="index" class="elevation-0 mt-1" style="min-width: 100%;" small>{{ button.title }}</v-btn>
                                            </v-row>
                                        </span>

                                        <!-- Fecha y Hora -->
                                        <span :style="message_item.direction === 'OUT' ? (message_item.contents.type=='file' ? 'position:absolute!important; bottom:15px; right:50px!important;':'position:absolute!important; right:50px!important;'): ''">
                                            <!-- Hour -->
                                            <span :style="message_item.direction === 'OUT' ? 'margin-left: 0px; bottom:-12px;': 'margin-left: 5px; bottom:1px; '" class="chat__timestamp">{{message_item.created_at.slice(11,19)}}</span>
                                            <!-- Seen -->
                                            <v-icon style="margin-bottom:-14px; margin-left: 5px;" v-if="message_item.direction === 'OUT' && JSON.stringify(message_item.statuses).includes('READ')" x-small color="primary" class="chat__checkmark">mdi-check-all</v-icon>
                                            <v-icon style="margin-bottom:-14px; margin-left: 5px;" v-else-if="message_item.direction === 'OUT' && JSON.stringify(message_item.statuses).includes('DELIVERED')" x-small color="grey" class="chat__checkmark">mdi-check-all</v-icon>
                                            <v-icon style="margin-bottom:-14px; margin-left: 5px;" v-else-if="message_item.direction === 'OUT' && JSON.stringify(message_item.statuses).includes('SENT')" x-small color="grey" class="chat__checkmark">mdi-check</v-icon>
                                            <v-icon style="margin-bottom:-14px; margin-left: 5px;" v-else-if="message_item.direction === 'OUT' && JSON.stringify(message_item.statuses).includes('CLOCK')" x-small color="grey" class="chat__checkmark">mdi-clock-outline</v-icon>
                                            <v-icon style="margin-bottom:-14px; margin-left: 5px;" v-else-if="message_item.direction === 'OUT' && JSON.stringify(message_item.statuses).includes('REJECTED')" x-small color="red" class="chat__checkmark">mdi-alert-circle-outline</v-icon>
                                        </span>
                                    </p>
                                </template>
                                <!--span>{{message_item}}</span-->
                            </v-tooltip>

                        </div>
                        <div style="position: fixed; bottom: 16vh; width: 850px; display: flex;">
                            <v-btn class="mx-auto" icon @click="scroll()">
                                <v-icon>mdi-chevron-down</v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <!-- Type Message-->
                    <v-row style="background:#f0f0f0; width:100%;" class="px-6 py-3 type ma-0">
                        <v-text-field 
                        v-if="expiration(lead.conversation.channel, lead.conversation.latest_session_message_time)" 
                        full-width 
                        rows="1" 
                        no-resize
                        auto-grow 
                        v-model="message" 
                        filled 
                        rounded 
                        dense 
                        single-line 
                        hide-details 
                        label="Escribe un mensaje aquí" 
                        color="#919191" 
                        @keydown.enter="sendMessage" 
                        prepend-icon="mdi-attachment" 
                        @click:prepend="fileDialog = true" 
                        append-outer-icon="mdi-send"
                        @click:append-outer="sendMessage()" 
                        ></v-text-field><!-- prepend-icon="mdi-attachment"  -->
                        <!--v-btn small style="margin:6.3px;" class="mr-0" v-else-if="!recording" icon @click="recordAudio"><v-icon>mdi-microphone</v-icon></v-btn>
                        <v-btn small style="margin:6.3px;" class="mr-0" v-else icon @click="stop()"><v-icon> mdi-stop-circle-outline </v-icon></v-btn-->
                        <div v-else style="text-align:center;">
                            Han pasado más de 24 horas de la utlima vez que el cliente envio un mensaje. 
                            <a style="color:white; background:#1976d2; font-size:14px; font-weight:500; border-radius:5px; padding:5px 10px;" @click="templatesDialog=true">Envía un mensaje de plantilla</a> 
                            y espera una respuesta para continuar con la conversación.
                            
                        </div>
                    </v-row>
                    <div id="audio" class="audio" controls></div>
                    <v-dialog v-model="fileDialog" width="600">
                        <v-card class="pa-6">
                            <vue-dropzone 
                            ref="myVueDropzone" 
                            id="dropzone" 
                            :options="dropzoneOptions" 
                            v-on:vdropzone-success="uploadSuccess" 
                            v-on:vdropzone-error="uploadError" 
                            v-on:vdropzone-removed-file="fileRemoved"/>

                                <v-row class="ma-0">
                                    <v-spacer/>
                                    <v-btn class="mt-4 elevation-0" small color="primary" @click="sendImage()" :disabled="disableButtonFileSend">Enviar</v-btn>
                                </v-row>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="templatesDialog" width="600">
                        <v-card class="pa-6">
                            <v-card @click="sendTemplateMessage(template), templatesDialog=false" class="pa-4 mb-4 elevation-0 border-message-template" v-for="(template, index) in templates" :key="index">
                                <v-card-subtitle class="pa-0 mb-2">{{template.name}}</v-card-subtitle>
                                <p class="chat__sender chat__message" style="padding-right:30px; max-width:100%!important; width:100%;">
                                    {{template.text}}
                                </p>
                            </v-card>
                        </v-card>
                    </v-dialog>
                </div>
            </v-col>
            <v-col md="3" class="pa-0" v-if="this.lead.funnel_phase.funnel.name!='Clientes'">
                <leadDetail v-bind:lead="lead"/>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>

//import InfiniteLoading from 'vue-infinite-loading';
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import leadDetail from '../leads/detail.vue'
import axios from "axios";
import EmojiPicker from "vue-emoji-picker";
export default {
    data() {
        return {
            templatesDialog:false,
            templates:[],
            interests:['plug', 'movil', 'mifi'],
            lead_interest:'',
            imageDilog:false,
            disableButtonFileSend: true,
            fileDialog:false,
            newFunnel:'',
            newPhase:'',
            client:{
                contract_date:'',
                delivery_time:'',
                user_id:'',
                origin_id:'',
                status_id:'',
                name:'',
                address:'',
                phone:'',
                email:'',
                rfc:'',
                razon_social:'',
                payment_method_id:'',
                payment_conditions:'',
                opportunity_area:'',
                consumptions:'',
                delivery_address:'',
                contact_medium:'',
                special_note:'',
                cfdi:'',
                bank_account_number:'',
                created_by_user_id:'',
                last_updated_by_user_id:''
            },
            tab:null,
            messages:[],
            asociarDialog:false,
            message:'',
            lead:'',
            funnel_phases:'',
            editInterest:false,
            editPhone:false,
            editEmail:false,
            page:1,
            pause:'no',
            recorder: null,
            chunks: [],
            device: null,
            blobObj: null,
            recording:false,
            pauseChange:true,
            dropzoneOptions: {
                url: process.env.VUE_APP_BACKEND_ROUTE + "api/v2/message/upload-file",
                addRemoveLinks: true,
                maxFiles: 1,
                thumbnailWidth: 400,
                maxFilesize: 5,
                dictDefaultMessage: 'Haz clic aquí o arrastra tu imagen/archivo a subir.',
                dictFallbackMessage: "Tu navegador no puede subir archivos arrastarndolos a la pantalla.",
                dictFileTooBig: "El tamaño del arfchivo ({{filesize}}MB) supera el tamaño máximo permitido {{maxFilesize}}MB.",
                dictInvalidFileType: "No puede cargar archivos de este tipo.",
                dictCancelUpload: "Cancelar carga",
                dictCancelUploadConfirmation: "Estás seguro de que deseas cancelar esta carga?",
                dictRemoveFile: "Eliminar",
                dictMaxFilesExceeded: "No puedes subir más archivos.",
            },
            file:'',
            fileLink:'',
            fileMimeType:'',
            fileName:''
        }
    },
    computed:{
        columnaSize(){
            if(this.lead.funnel_phase.funnel.name!='Clientes'){
                return 9
            }else{
                return 12
            }
        },
        funnels(){
            return this.$store.state.funnel.funnels
        },
        users(){
            return this.$store.state.user.users
        },
        currentUser(){
            return this.$store.state.currentUser.user
        },
        phases(){
            return this.$store.state.funnel.phases.filter(phase=>phase.funnel_id == this.newFunnel).filter(phase=>phase.id>0)
        },
        buttonDisabled(){
            if(this.newFunnel == this.lead.funnel_phase.funnel.id && this.newPhase == this.lead.funnel_phase.id){
                return true
            }else{
                return false
            }
        }
    },
    components: {
        EmojiPicker,
        'leadDetail':leadDetail,
        vueDropzone: vue2Dropzone
    }, 
    props:{
        propData:Object
    }, 
    created(){
        if(this.lead.additional_data!=null){
            this.lead_interest = this.lead.additional_data.interest
        }
        this.lead = this.propData.lead
        if(!this.expiration(this.lead.conversation.channel, this.lead.conversation.latest_session_message_time)){
            axios({
                method: "GET",
                url: "https://api.zenvia.com/v2/templates/",
                headers: {
                    "Content-Type": "application/json",
                    "X-API-TOKEN": process.env.VUE_APP_ZENVIA_X_API_TOKEN,
                }
            }).then(response=>{
                this.templates = response.data.map(t=>{return{
                    ...t,
                    text:t.text.replace(/{{clientName}}/g, this.propData.lead.name).replace(/{{user}}/g, this.currentUser.name + (this.currentUser.last ? ` ${this.currentUser.last}` : ''))
                }})
            })
        }
        this.newPhase = this.lead.funnel_phase.id*1
        this.newFunnel = this.lead.funnel_phase.funnel.id*1
        this.funnel_phases = this.propData.funnel_phases
        if(this.propData.lead.conversation!=undefined){
            this.messages.push(this.propData.lead.conversation.latest_message)
        }
        this.scroll()
        axios.post(process.env.VUE_APP_BACKEND_ROUTE + "api/v2/conversation/mark_messages_as_read", {conversation_id:this.lead.conversation.id, user_id:this.currentUser.id})
    },
    mounted() {
        
        Echo.channel('new_message').listen('NewMessageEvent', (e) => {
            console.log('si jalan perro')
            console.log(e)
            var new_message = {}
            new_message = e[0]

            if(new_message.contents.type!="template"){
                this.lead.conversation.latest_session_message_time = new_message.created_at
            }


            if(new_message.direction=='OUT'){
                var channelId = new_message.to
            }else if(new_message.direction=='IN'){
                var channelId = new_message.from
            }


            var index = this.messages.indexOf(new_message)

            if(new_message.direction=='IN'&&channelId==this.lead.conversation.channelId&&index<0){
                this.messages.push('mark_messages_as_read')
                if(this.currentUser.id!=1&&this.currentUser.id!=2&&this.currentUser.id!=37){
                    console.log('perro')
                    axios.post(process.env.VUE_APP_BACKEND_ROUTE + "api/v2/conversation/mark_messages_as_read", {conversation_id:this.lead.conversation.id, user_id:this.currentUser.id})
                }
                this.$nextTick(() => {
                    this.scroll()
                })
            }
        })
        Echo.channel('new_message_status_event').listen('NewMessageStatusEvent', (e) => {
            
        })
    },
    methods:{
        imageMargin(direction){
            if(direction == 'OUT'){
                return 'margin-right:-10px;'
            }else if(direction == 'IN'){
                return 'margin-right:-30px;'
            }
        },
        expiration(channel, date) {
            if (channel === 'whatsapp') {
                const fechaInicio = new Date(date).getTime();
                const fechaFin = Date.now();
                const diff = (fechaFin - fechaInicio) / (1000 * 60 * 60);
                return diff <= 24
            } else {
                return true;
            }
        },
        downloadWithAxios(url) {
            axios({
                method: 'get',
                url,
                responseType: 'arraybuffer',
            }).then((response) => {
                this.forceFileDownload(response, 'UNOCRM_INETN_FILE_'+new Date().toLocaleString("sv-SE", {timeZone: "America/Monterrey"}).toString().slice(0, 10)+'_'+new Date().toLocaleString("sv-SE", {timeZone: "America/Monterrey"}).toString().slice(11, 19)+'.jpg')
            }).catch(() => console.log('error occured'))
        },
        forceFileDownload(response, title) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', title)
            document.body.appendChild(link)
            link.click()
        },
        saveFunelAndFace(){
            axios.patch(process.env.VUE_APP_BACKEND_ROUTE + "api/v2/leads/"+this.lead.id, {funnel_phase_id:this.newPhase.toString()})
        },
        convertToClient(){

        },
        recordAudio() {
            this.recording = true
            this.device = navigator.mediaDevices.getUserMedia({ audio: true });
            this.device.then((stream) => {
                this.recorder = new MediaRecorder(stream);
                this.recorder.ondataavailable = (e) => {
                    this.chunks.push(e.data);
                    if (this.recorder.state === "inactive") {
                        var audio = document.getElementById("audio");
                        var mainaudio = document.createElement("audio");
                        mainaudio.setAttribute("controls", "controls");
                        audio.appendChild(mainaudio);
                        var url = URL.createObjectURL(new Blob(this.chunks, { type: "audio/wav" }))
                        mainaudio.innerHTML = '<source src="' + url.slice(5,url.length) + '" type="audio/wav" />';
                        //console.log(url.slice(5,url.length))
                        this.chunks = [];
                        this.blobObj = null;
                    }
                };
                this.recorder.start();
            });
        },
        stop() {
            this.recording = false
            this.recorder.stop();
        },
        callback (data) {
            console.debug(data)
        },
        hourFormat(date, direction){
            if(direction=='IN'){
                return date.slice(11,16)
            }else if(direction=='OUT'){
                return date.slice(11,16)
            }

        },
        dateFormat(date){
            if(date!=undefined){
                // Creamos el objeto fecha instanciándolo con la clase Date
                const fecha = new Date((date.slice(0,10) + ' 00:00:00').replace(/-/g, "/"));
                
                // Creamos array con los días de la semana
                const dias_semana = ['Lunes', 'martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
                //Creamos constante para el dia de hoy
                const hoy = new Date((new Date().toLocaleString("sv-SE", {timeZone: "America/Monterrey"}).slice(0,10) + ' 00:00:00').replace(/-/g, "/"))
                //sacamos diferencia
                const difference = (Date.UTC(hoy.getFullYear(), hoy.getMonth(), hoy.getDate()) - Date.UTC(fecha.getFullYear(), fecha.getMonth(), fecha.getDate()))/(1000*60*60*24)

                if(!fecha.getDay()){
                    var day = 6
                }else{
                    var day = fecha.getDay() - 1
                }

                if((difference)<7){
                    if(difference==0){
                        return 'Hoy'
                    }else if(difference==1){
                        return 'Ayer'
                    }else{
                        return dias_semana[day]
                    }
                }else{
                    // Creamos array con los meses del año
                    const meses = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'];
                    // Construimos el formato de salida
                    if(fecha.getUTCFullYear()!=new Date().getUTCFullYear()){
                        return dias_semana[day] + ', ' + fecha.getDate() + ' de ' + meses[fecha.getMonth()];
                    }else{
                        return dias_semana[day] + ', ' + fecha.getDate() + ' de ' + meses[fecha.getMonth()] + ' de ' + fecha.getUTCFullYear();
                    }
                }
            }
        },
        scroll() {
            this.messages !== null &&
            this.messages !== undefined
            ? this.scrollToEnd()
            : null;
        },
        save(){
            var editedItem = {
                id: this.lead.id,
                additional_data:{interest: this.lead_interest},
                phone: this.lead.phone,
                email: this.lead.email
            }
            axios.patch(process.env.VUE_APP_BACKEND_ROUTE + "api/v2/leads",Object.assign(editedItem)).then(response=>{
                this.editInterest = false
                this.lead_interest = ''
                this.editPhone = false
                this.editEmail = false
            })
        },
        sendImage(){
            var messageInput = {
                conversation_id: this.propData.lead.conversation.id,
                user_id: this.currentUser.id,
                contents:{
                    type: "file",
                    fileUrl: this.fileLink,
                    fileMimeType: this.fileMimeType,
                    fileName: this.fileName
                },
                channel: this.propData.lead.conversation.channel,
                uuid:'',
                from:'',
                to:'',
                direction:'OUT',
                statuses:[{code:'CLOCK'}],
                zenvia_timestamp: new Date().toLocaleString("sv-SE", {timeZone: "America/Monterrey"}),
                created_at: new Date().toLocaleString("sv-SE", {timeZone: "America/Monterrey"}),
                meessage_datetime: new Date().toLocaleString("sv-SE", {timeZone: "America/Monterrey"}),
            }
            this.messages.push(messageInput)
            if(this.propData.lead.conversation.channel == 'whatsapp'){
                var server = process.env.VUE_APP_ZENVIA_WHATSAPP_SERVER
            }else if(this.propData.lead.conversation.channel == 'facebook'){
                var server = process.env.VUE_APP_ZENVIA_FACEBOOK_SERVER
            }
            else if(this.propData.lead.conversation.channel == 'instagram'){
                var server = process.env.VUE_APP_ZENVIA_INSTAGRAM_SERVER
            }
            if(this.fileMimeType!='png'&&this.fileMimeType!='jpg'&&this.fileMimeType!='jpeg'){
                var zenvia_contents = [{
                    type: "file",
                    fileUrl: this.fileLink,
                    fileMimeType: 'application/'+this.fileMimeType,
                    fileName: this.fileName
                }]
            }else{
                {
                    var zenvia_contents = [{
                        type: "file",
                        fileUrl: this.fileLink
                    }]
                }
            }
            axios({
                method: "POST",
                url: "https://api.zenvia.com/v2/channels/" + this.propData.lead.conversation.channel + "/messages",
                headers: {
                    "Content-Type": "application/json",
                    "X-API-TOKEN": process.env.VUE_APP_ZENVIA_X_API_TOKEN,
                },
                data: {
                    from:server,
                    to:this.propData.lead.conversation.channelId,
                    contents:zenvia_contents
                },
            }).then(response => {
                messageInput.statuses[0].code = 'SENT'
                messageInput.uuid = response.data.id,
                messageInput.from = response.data.from,
                messageInput.to = response.data.to,
                axios.post(process.env.VUE_APP_BACKEND_ROUTE + "api/v2/messages",Object.assign(messageInput)).then(response=>{
                    this.messages[this.messages.length-1] = messageInput
                    this.scroll()
                    this.$refs.myVueDropzone.removeFile(this.file)
                    this.fileDialog = false
                })
            })
        },
        sendMessage(){
            
            if(this.message!=''){
                var message = this.message
                this.message = ''
                var messageInput = {
                    conversation_id: this.propData.lead.conversation.id,
                    user_id: this.currentUser.id,
                    contents:{
                        type: "text",
                        text: message
                    },
                    channel: this.propData.lead.conversation.channel,
                    uuid:'',
                    from:'',
                    to:'',
                    direction:'OUT',
                    statuses:[{code:'CLOCK'}],
                    zenvia_timestamp: new Date().toLocaleString("sv-SE", {timeZone: "America/Monterrey"}),
                    created_at: new Date().toLocaleString("sv-SE", {timeZone: "America/Monterrey"}),
                    meessage_datetime: new Date().toLocaleString("sv-SE", {timeZone: "America/Monterrey"}),
                }
                this.messages.push(messageInput)
                if(this.propData.lead.conversation.channel == 'whatsapp'){
                    var server = process.env.VUE_APP_ZENVIA_WHATSAPP_SERVER
                }else if(this.propData.lead.conversation.channel == 'facebook'){
                    var server = "giant-whale"
                }
                else if(this.propData.lead.conversation.channel == 'instagram'){
                    var server = "clear-pruner"
                }
                axios({
                    method: "POST",
                    url: "https://api.zenvia.com/v2/channels/" + this.propData.lead.conversation.channel + "/messages",
                    headers: {
                        "Content-Type": "application/json",
                        "X-API-TOKEN": process.env.VUE_APP_ZENVIA_X_API_TOKEN,
                    },
                    data: {
                        from:server,
                        to:this.propData.lead.conversation.channelId,
                        contents:[{
                            type:"text",
                            text:message
                        }]
                    },
                }).then(response => {
                    messageInput.statuses[0].code = 'SENT'
                    messageInput.uuid = response.data.id,
                    messageInput.from = response.data.from,
                    messageInput.to = response.data.to,
                    axios.post(process.env.VUE_APP_BACKEND_ROUTE + "api/v2/messages",Object.assign(messageInput)).then(response=>{
                        this.messages[this.messages.length-1] = messageInput
                        this.scroll()
                    })
                })
            }else{
                //console.log(new Date().toLocaleString("sv-SE", {timeZone: "America/Monterrey"}),)
            }
        },
        sendTemplateMessage(template){
            /*if(this.propData.lead.conversation.zenviaChannelId == "5218185263303"){
                var zenviaTemplateId = "21fecd7d-2375-4e91-b8c1-fa3ae19b9f35"
            }else if(this.propData.lead.conversation.zenviaChannelId == "5218137058408"){
                var zenviaTemplateId = "e73c0b4a-191e-4fec-b636-f6461b147ffe"
            }*/
            var messageInput = {
                conversation_id: this.propData.lead.conversation.id,
                user_id: this.currentUser.id,
                contents:{
                    type:"template",
                    templateId:template.id,
                    fields:{
                        clientName:this.propData.lead.name,
                        user:this.currentUser.name + (this.currentUser.last!=null?(' ' + this.currentUser.last):'')
                    },
                    text:template.text
                },
                channel: this.propData.lead.conversation.channel,
                uuid:'',
                from:'',
                to:'',
                direction:'OUT',
                statuses:[{code:'CLOCK'}],
                zenvia_timestamp: new Date().toLocaleString("sv-SE", {timeZone: "America/Monterrey"}),
                created_at: new Date().toLocaleString("sv-SE", {timeZone: "America/Monterrey"}),
                meessage_datetime: new Date().toLocaleString("sv-SE", {timeZone: "America/Monterrey"}),
            }
            this.messages.push(messageInput)
            if(this.propData.lead.conversation.channel == 'whatsapp'){
                var server = process.env.VUE_APP_ZENVIA_WHATSAPP_SERVER
            }else if(this.propData.lead.conversation.channel == 'facebook'){
                var server = process.env.VUE_APP_ZENVIA_FACEBOOK_SERVER
            }
            else if(this.propData.lead.conversation.channel == 'instagram'){
                var server = process.env.VUE_APP_ZENVIA_INSTAGRAM_SERVER
            }
            axios({
                method: "POST",
                url: "https://api.zenvia.com/v2/channels/" + this.propData.lead.conversation.channel + "/messages",
                headers: {
                    "Content-Type": "application/json",
                    "X-API-TOKEN": process.env.VUE_APP_ZENVIA_X_API_TOKEN,
                },
                data: {
                    from:server,
                    to:this.propData.lead.conversation.channelId,
                    contents:[{
                        type:"template",
                        templateId:template.id,
                        fields:{
                            clientName:this.propData.lead.name,
                            user:this.currentUser.name + (this.currentUser.last!=null?(' ' + this.currentUser.last):'') 
                        }
                    }],
                },
            }).then(response => {
                messageInput.statuses[0].code = 'SENT'
                messageInput.uuid = response.data.id,
                messageInput.from = response.data.from,
                messageInput.to = response.data.to,
                axios.post(process.env.VUE_APP_BACKEND_ROUTE + "api/v2/messages",Object.assign(messageInput)).then(response=>{
                    this.messages[this.messages.length-1] = messageInput
                    this.scroll()
                })
            })
        },
        getMessages($state) {
            axios.get(process.env.VUE_APP_BACKEND_ROUTE + "api/v2/messages?filter[conversation_id]=" + this.propData.lead.conversation.id + "&page=" + this.page + "&itemsPerPage=30")
            .then(response=>{
                const response_data = response.data.data
                if (response_data.length) {
                    var messages = response_data.sort(function(a, b) {
                        return new Date(a.created_at_with_seconds).getTime() - new Date(b.created_at_with_seconds).getTime();
                    });
                    if (this.page === 1) {
                        this.messages = messages;
                    } else {
                        this.messages = this.messages.concat(messages).sort(function(a, b) {
                            return new Date(a.created_at_with_seconds).getTime() - new Date(b.created_at_with_seconds).getTime();
                        });
                    }
                    $state.loaded();
                    this.page += 1;
                } else {
                    $state.complete();
                }
            })
        },
        checkPadding(direction, is_file){
            if(direction=='OUT'){
                if(is_file){
                    return '20px!important'
                }else{
                    return '70px!important; min-width:80px!important; min-height:40px!important; padding-bottom:12px!important;'
                }
            }else if(direction=='IN'){
                return '55px!important'
            }
        },
        scrollToEnd() {
            const content = this.$refs.container;
            if(content!=undefined){
                content.scrollTop = content.scrollHeight;
            }
        },
        uploadSuccess(file, response) {
            this.file = file
            console.log('File Successfully Uploaded with file name: ' + response.file);
            this.fileLink = process.env.VUE_APP_BACKEND_ROUTE + 'zenvia-files/' + response.file;
            this.fileMimeType = response.extension
            this.fileName = response.fileName
            this.disableButtonFileSend = false;
        },
        uploadError(file, message) {
            console.log('An Error Occurred');
        },
        fileRemoved(file) {
            this.$refs.myVueDropzone.removeFile(file)
            this.file = ''
            this.fileLink = ''
            this.fileMimeType = ''
            this.fileName = ''
        },
    },
    watch:{
        lead:{
            handler(){
                if(!this.expiration(this.lead.conversation.channel, this.lead.conversation.latest_session_message_time)){
                    axios({
                        method: "GET",
                        url: "https://api.zenvia.com/v2/templates/",
                        headers: {
                            "Content-Type": "application/json",
                            "X-API-TOKEN": process.env.VUE_APP_ZENVIA_X_API_TOKEN,
                        }
                    }).then(response=>{
                        this.templates = response.data.map(t=>{return{
                            ...t,
                            text:t.text.replace(/{{clientName}}/g, this.propData.lead.name).replace(/{{user}}/g, this.currentUser.name + (this.currentUser.last ? ` ${this.currentUser.last}` : ''))
                        }})
                    })
                }
            }, deep:true
        }
    }
}
</script>
<style>
    .dz-error-message {
        margin-left: 250px!important;
        margin-top: -70px!important;
    }
    .chat__timestamp {
        font-size: 11px;
        position:absolute;
        white-space: nowrap;
    }
    .chat__checkmark {
        position:absolute;
        bottom:-9px;
        right:-37px;
    }
    .chat__message {
        position: relative;
        font-size: 14.2px;
        padding: 6px 0px 8px 9px;
        border-radius: 7.5px;
        background-color: #ffffff;
        width: fit-content;
        margin-bottom: 30px;
        max-width: 65%;
    }
    .chat__reciever:after {
        content: "";
        position: absolute;
        margin-top: -6px;
        margin-left: -5px;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid #ffffff !important;
        transform: rotate(42deg);
        left: -6px;
        top: 4px;
    }
    .chat__sender {
        margin-left: auto;
        background-color: #dcf8c6;
    }
    .chat__sender:after {
        content: "";
        position: absolute;
        margin-top: -6px;
        margin-left: -5px;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid #dcf8c6 !important;
        transform: rotate(-42deg);
        right: -10px;
        top: 4px;
    }
    .chat__body{
        flex: 1;
        overflow: scroll;
    }
    .search-bar .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
        box-shadow: 0px 3px 1px -2px rgba(255, 255, 255, 0), 0px 2px 2px 0px rgba(255, 255, 255, 0), 0px 1px 5px 0px rgba(255, 255, 255, 0)!important;
    }
    .search-bar .v-label {
        font-size: 15px;
        color:#919191;
    }
    .search-bar .mdi-magnify::before {
        font-size: 18px;
        color:#919191;
    }
    .wrapper {
        position: relative;
        display: inline-block;
    }
    .regular-input {
        padding: 0.5rem 1rem;
        border-radius: 3px;
        border: 1px solid #ccc;
        width: 20rem;
        height: 12rem;
        outline: none;
    }
    .regular-input:focus {
        box-shadow: 0 0 0 3px rgba(66,153,225,.5);
    }
    .regular-input {
        padding: 0.5rem 1rem;
        border-radius: 3px;
        border: 1px solid #ccc;
        width: 20rem;
        height: 12rem;
        outline: none;
    }
    .regular-input:focus {
        box-shadow: 0 0 0 3px rgba(66,153,225,.5);
    }
    .perros .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
        background: white;
    }
    .perros textarea{
        overflow: auto!important;
        max-height: 120px!important;
    }
    .v-icon.notranslate.v-icon--link.mdi.theme--light{
        color:#919191!important;
    }
    .perros .v-input__control{
        margin: 0px 10px!important;
    }
    .khe .v-badge__badge.primary{    
        inset: auto auto -5px -5px!important;
    }
    @media (max-width: 980px){
        .perros{
            width: 100vw!important;
        }
        header{
            margin-top:-13px;
        }
        .chat__body{
            max-height: 62vh!important;
            min-height: 5vh!important;
        }
        .altocel{
            height:calc(100vh - 60px - 56px)!important;
        }
        .altocel2{
            height:calc(100vh - 56px)!important;
        }
    }
    /*
    .space .theme--light.v-input input, .theme--light.v-input textarea {
        padding: 15px 20px;
    }
    .v-input__slot{
        margin-bottom: 0px!important;
    }
    */
    .border-message-template{
        border: solid 1px #ccd2d4!important;
        cursor: pointer;
    }
    .border-message-template:hover{
        border-color:#1876d2!important;
    }
</style>